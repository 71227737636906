<template>
  <div class="LessonEntityDetails__Component">
    <!-- <FieldInlineText label="שם הפנייה" @onChange="onChange" v-model="model.description" /> -->
    <FieldInlineSelect label="סטטוס" :hideHover="true" @onChange="onChange" :filterable="false" :clearable="false"
      :options="[{ Value: 1, Text: 'חדשה' },
    { Value: 2, Text: 'בטיפול' },
    { Value: 3, Text: 'הסתיימה' }]" v-model="model.status" />
    <FieldInlineSelect label="עדכון נושא" :hideHover="true" @onChange="onChange" :filterable="false" :clearable="false"
      :optionsAsync="getRequestMessageTypes" v-model="model.messagesInCourseTypesId" />

    <!-- <FieldInlineSelect :multiple="true" label="תיוג צוות פניות" :hideHover="true" @onChange="onChange" :filterable="false"
      :clearable="false" :optionsAsync="getAdminUsers" v-model="model.messagesInCourseTypesId" /> -->

    <b-field label="תיוג צוות פניות">
      <v-select dir="rtl" append-to-body label="Text"  v-model="model.taggedAdmins" :options="adminUsers" :clearable="true"
        :multiple="true" @input="onChange" :reduce="(item) => item.Value" :getOptionLabel="(item) => `${item.Text}`" :searchable="true" :close-on-select="false" :placeholder="'בחר ערך מהרשימה'">
        <div slot="no-options">לא נמצאו ערכים דומים</div>
      </v-select>
    </b-field>
    <!-- <FieldInlineTextArea label="כתיבת תגובה פנימית" @onChange="onChange" v-model="model.isUserSatisfied" /> -->
    <b-switch class="ltr" @input="onChange" v-model="model.allowedPublication" :true-value="true" :false-value="false">
      {{ model.allowedPublication ? "לפרסום" : "לא לפרסם" }}
    </b-switch>
  </div>
</template>

<script>
import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
// import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
// import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
// import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
// import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import CoursesService from "@/services/CoursesService";
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LessonEntityDetails',
  components: {
    // FieldInlineText,
    FieldInlineTextArea,
    FieldInlineSelect,
    vSelect
    // FieldInlineDate,
    // FieldInlineTextArea,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        id: null,
        description: null,
        allowedPublication: null,
        courseId: null,
        createdDate: null,
        exerciseId: null,
        fullname: null,
        isUserSatisfied: null,
        lessonId: null,
        messagesInCourseTypesId: null,
        questionId: null,
        questionSubject: null,
        sentFromPage: null,
        status: null,
        taggedAdmins: []
      },
      adminUsers: [],
    };
  },
  computed: {
    ...mapGetters('request', [
      'form',
    ]),
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
    this.getAdminUsers();
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    getRequestMessageTypes() {
      return () =>
        CoursesService.getRequestMessageTypes(this.model.courseId).then((r) =>
          r.data.items.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getAdminUsers() {
      CoursesService.getUsersByCourse(this.model.courseId, {
        UserType: 1
      }).then((r) => this.adminUsers = r.data.items.map((i) => ({ Value: i.id, Text: `${i.privateName} ${i.lastName}` }))
      );
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeLessonDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('request', [
      'storeLessonDetails',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
</style>
