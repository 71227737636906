var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c("FieldInlineSelect", {
        attrs: {
          label: "סטטוס",
          hideHover: true,
          filterable: false,
          clearable: false,
          options: [
            { Value: 1, Text: "חדשה" },
            { Value: 2, Text: "בטיפול" },
            { Value: 3, Text: "הסתיימה" }
          ]
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.status,
          callback: function($$v) {
            _vm.$set(_vm.model, "status", $$v)
          },
          expression: "model.status"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "עדכון נושא",
          hideHover: true,
          filterable: false,
          clearable: false,
          optionsAsync: _vm.getRequestMessageTypes
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.messagesInCourseTypesId,
          callback: function($$v) {
            _vm.$set(_vm.model, "messagesInCourseTypesId", $$v)
          },
          expression: "model.messagesInCourseTypesId"
        }
      }),
      _c(
        "b-field",
        { attrs: { label: "תיוג צוות פניות" } },
        [
          _c(
            "v-select",
            {
              attrs: {
                dir: "rtl",
                "append-to-body": "",
                label: "Text",
                options: _vm.adminUsers,
                clearable: true,
                multiple: true,
                reduce: function(item) {
                  return item.Value
                },
                getOptionLabel: function(item) {
                  return "" + item.Text
                },
                searchable: true,
                "close-on-select": false,
                placeholder: "בחר ערך מהרשימה"
              },
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.taggedAdmins,
                callback: function($$v) {
                  _vm.$set(_vm.model, "taggedAdmins", $$v)
                },
                expression: "model.taggedAdmins"
              }
            },
            [
              _c("div", { attrs: { slot: "no-options" }, slot: "no-options" }, [
                _vm._v("לא נמצאו ערכים דומים")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "b-switch",
        {
          staticClass: "ltr",
          attrs: { "true-value": true, "false-value": false },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.allowedPublication,
            callback: function($$v) {
              _vm.$set(_vm.model, "allowedPublication", $$v)
            },
            expression: "model.allowedPublication"
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.model.allowedPublication ? "לפרסום" : "לא לפרסם") +
              " "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }